/* eslint-disable custom-rules/avoid-process-env */
/* eslint-disable unicorn/prefer-global-this */

export const getEnvVariableUnsafe = (key: string): string | undefined => {
	let value: string | undefined = undefined;
	if (typeof window === "undefined" || window.env === undefined) {
		value = process.env[key];
	} else {
		value = process.env[key] ?? window.env[key];
	}

	return value;
};

// Remix with vite doesn't accept process.env on the client
export const getEnvVariable = (key: string): string => {
	const value: string | undefined | null = getEnvVariableUnsafe(key);

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (value === undefined || value === null) {
		throw new Error(`Environment variable ${key} not found`);
	}

	return value;
};
